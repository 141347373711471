import React, { useState } from 'react';
import { dates } from '../utils/dates'; // Ensure you have the dates utility or update accordingly
import '../App.css'; // Import the CSS for styling
import OpenAI from 'openai';
import image1 from "../images/logo-dinith-text.jpeg"
import image2 from "../images/add.svg"
import image3 from "../images/loader.svg"

const StockPredictions = () => {
  const [tickersArr, setTickersArr] = useState([]);
  const [loading, setLoading] = useState(false);
  const [apiMessage, setApiMessage] = useState('Querying Stocks API...');
  const [report, setReport] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const tickerInput = document.getElementById('ticker-input');
    if (tickerInput.value.length > 2) {
      setTickersArr([...tickersArr, tickerInput.value.toUpperCase()]);
      tickerInput.value = '';
      document.querySelector('.generate-report-btn').disabled = false;
    } else {
      const label = document.getElementsByTagName('label')[0];
      label.style.color = 'red';
      label.textContent = 'You must add at least one ticker. A ticker is a 3 letter or more code for a stock. E.g TSLA for Tesla.';
    }
  };

  const renderTickers = () => {
    return tickersArr.map((ticker, index) => (
      <span key={index} className="ticker">
        {ticker}
      </span>
    ));
  };

  const fetchStockData = async () => {
    setLoading(true);
    try {
      const stockData = await Promise.all(
        tickersArr.map(async (ticker) => {
          const url = `https://polygon-api-worker.dinithvanderlan100-c4c.workers.dev/?ticker=${ticker}&startDate=${dates.startDate}&endDate=${dates.endDate}`
          const response = await fetch(url);
          const data = await response.text();
          const status = await response.status;
          if (status === 200) {
            setApiMessage('Creating report...');
            return data;
          } else {
            setApiMessage('There was an error fetching stock data.');
          }
        })
      );
      fetchReport(stockData.join(''));
    } catch (err) {
      setApiMessage('There was an error fetching stock data.');
      console.error(err.message);
    }
  };

  const fetchReport = async (data) => {
    const messages = [
      {
        role: 'system',
        content:
          'You are a trading guru. Given data on share prices over the past 3 days, write a report of no more than 150 words describing the stocks performance and recommending whether to buy, hold or sell. Use the examples provided between ### to set the style your response.',
      },
      {
        role: 'user',
        content: `${data}
        ###
        OK baby, hold on tight! You are going to haate this! Over the past three days, Tesla (TSLA) shares have plummetted. The stock opened at $223.98 and closed at $202.11 on the third day, with some jumping around in the meantime. This is a great time to buy, baby! But not a great time to sell! But I'm not done! Apple (AAPL) stocks have gone stratospheric! This is a seriously hot stock right now. They opened at $166.38 and closed at $182.89 on day three. So all in all, I would hold on to Tesla shares tight if you already have them - they might bounce right back up and head to the stars! They are volatile stock, so expect the unexpected. For APPL stock, how much do you need the money? Sell now and take the profits or hang on and wait for more! If it were me, I would hang on because this stock is on fire right now!!! Apple are throwing a Wall Street party and y'all invited!
        ###
        Apple (AAPL) is the supernova in the stock sky – it shot up from $150.22 to a jaw-dropping $175.36 by the close of day three. We’re talking about a stock that’s hotter than a pepper sprout in a chilli cook-off, and it’s showing no signs of cooling down! If you’re sitting on AAPL stock, you might as well be sitting on the throne of Midas. Hold on to it, ride that rocket, and watch the fireworks, because this baby is just getting warmed up! Then there’s Meta (META), the heartthrob with a penchant for drama. It winked at us with an opening of $142.50, but by the end of the thrill ride, it was at $135.90, leaving us a little lovesick. It’s the wild horse of the stock corral, bucking and kicking, ready for a comeback. META is not for the weak-kneed So, sugar, what’s it going to be? For AAPL, my advice is to stay on that gravy train. As for META, keep your spurs on and be ready for the rally.
        ###
        `,
      },
    ];

    try {
      try {

        const openai = new OpenAI({
          apiKey: "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",
          dangerouslyAllowBrowser: true,
          baseURL: 'https://gateway.ai.cloudflare.com/v1/bc4c4ae143e8f7eee99687eeedee5c1e/stock-prediction/openai'
        })

        const response = await openai.chat.completions.create({
          model: 'gpt-3.5-turbo-1106',
          messages: messages,
          temperature: 1.1,
          presence_penalty: 0,
          frequency_penalty: 0
        })

        renderReport(response.choices[0].message.content)

      } catch (err) {
        console.log('Error:', err)
      }
    } catch (err) {
      console.error(err.message);
      setApiMessage('Unable to access AI. Please refresh and try again.');
    }
  };

  const renderReport = (output) => {
    setLoading(false);
    setReport(output);
  };

  return (
    <div>
      <header>
        <img src={image1} alt="Dodgy Dave's Stock Predictions" />
      </header>
      <main>
        <section className="action-panel">
          <form id="ticker-input-form" onSubmit={handleSubmit}>
            <label htmlFor="ticker-input">
              Add up to 3 stock tickers below to get a super accurate stock predictions report👇
            </label>
            <div className="form-input-control">
              <input type="text" id="ticker-input" placeholder="MSFT" />
              <button type="submit" className="add-ticker-btn">
                <img src={image2} className="add-ticker-svg" alt="add" />
              </button>
            </div>
          </form>
          <p className="ticker-choice-display">{renderTickers()}</p>
          <button className="generate-report-btn" type="button" onClick={fetchStockData} disabled={tickersArr.length === 0}>
            Generate Report
          </button>
          <p className="tag-line">Always correct 15% of the time!</p>
        </section>
        {loading && (
          <section className="loading-panel">
            <img src={image3} alt="loading" />
            <div id="api-message">{apiMessage}</div>
          </section>
        )}
        <div className='report'>
          <h2>The report</h2>
          <p>{report}</p>
        </div>
      </main>
    </div>
  );
};

export default StockPredictions;
